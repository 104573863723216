<template>
 <form method="post" @submit.prevent="submit" @keydown="clearErrors($event.target.name)">
  
  <div class="alert text-center" :class="{ 'alert-success': form.succeeded }" id="result" style="height:30px;padding:3px">
    <span v-if="! form.succeeded">
      Sign in to start your session
    </span>
    <span v-else>
      Logged in! <i class="fa fa-refresh fa-spin"></i>
      Entering...
    </span>
  </div>

  <div class="form-group has-feedback" :class="{ 'has-error': form.errors.has('email') }" v-if="type === 'email'">
   <input type="email" class="form-control" :placeholder="placeholder" :name="name" value="" v-model="form.email" @change="adddomain" autofocus/>
   <span class="glyphicon form-control-feedback" :class="[icon]"></span>
   <transition name="fade">
    <span class="help-block" v-if="form.errors.has('email')" v-text="form.errors.get('email')" id="validation_error_email"></span>
   </transition>
  </div>

  <div class="form-group has-feedback" :class="{ 'has-error': form.errors.has('username') }" v-else>
   <input type="text" class="form-control" :placeholder="placeholder" :name="name" v-model="form.username" @change="adddomain" autofocus/>
   <span class="glyphicon form-control-feedback" :class="[icon]"></span>
   <transition name="fade">
    <span class="help-block" v-if="form.errors.has('username')" v-text="form.errors.get('username')" id="validation_error_username"></span>
   </transition>
  </div>


  <div class="form-group has-feedback" :class="{ 'has-error': form.errors.has('password') }">
   <input type="password" class="form-control" placeholder="Password" name="password" v-model="form.password"/>
   <span class="glyphicon glyphicon-lock form-control-feedback"></span>
   <transition name="fade">
    <span class="help-block" v-if="form.errors.has('password')" v-text="form.errors.get('password')" id="validation_error_password"></span>
   </transition>
  </div>
  <div class="row">
    <div class="col-xs-8">
      <p style="font-size:10px; line-height:12px; color:lightgrey">
        <i class="fa fa-info-circle"></i>
        remember to logout to quit your session. <br>
        it will be automatically terminated after 30 mins of inactivity.
      </p>
    </div>
    <div class="col-xs-4">
      <button type="submit" :class="{ 'btn-primary': ! loading && ! form.errors.any() }" class="btn btn-block btn-flat" v-text="`Sign In`" :disabled="form.errors.any()"><i v-if="loading || form.submitting" class="fa fa-refresh fa-spin"></i></button>
    </div>
  </div>
 </form>
</template>

<style src="./fade.css"></style>

<script>
import Form from 'acacha-forms'
import redirect from './redirect'

export default {
  mixins: [redirect],
  data: function () {
    let form = new Form({ username: '', password: '', remember: '' })
    if (this.name === 'email') {
      form = new Form({ email: '', password: '', remember: '' })
    }
    return {
      loading: false,
      form: form
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    domain: {
      type: String,
      required: false
    }
  },
  computed: {
    placeholder: function () {
      if (this.name === 'email') return 'Email'
      return 'Username'
    },
    type: function () {
      if (this.name === 'email') return 'email'
      return 'text'
    },
    icon: function () {
      if (this.name === 'email') return 'glyphicon-envelope'
      return 'glyphicon-user'
    }
  },
  watch: {
    'form.remember': function (value) {
      if (value) {
        $('input').iCheck('check')
      } else {
        $('input').iCheck('uncheck')
      }
    }
  },
  methods: {
    async submit () {
      if (this.form.username.includes('@')) {
        notifyW('please insert your username, instead of email')
        return
      }
      this.loading = true
      try {
        let call = await this.form.post('/login')
      }
      catch (error) {
        if (error.response.status == 419) {
          console.log(error.response)
          notifyE('ERROR')
          return 
        }
        if (error.response.status == 422) {
          this.loading = false
          notifyE('unauthenticated')
          return
        }
        // se l'utente è già connesso in altra finestra/scheda
        if (error.config.url.includes('login') && error.response.status == 419) {
          dbg('here')
          // location.reload()
          return
        }
      }

      var { username, password } = this.form
      try {
        let { data } = await api.post('/api/v1/auth/login', { username, password })
        let { token, user } = data
        localStorage.setItem('token', token)
        localStorage.setItem('user', btoa(JSON.stringify(user)))
        
        window.location = '/'
      }
      catch(error) {
        callErrors(error)
      }
    },
    adddomain() {
      if (this.type === 'email') return
      if (this.domain === '') return
      if (this.form.username.endsWith(this.domain)) return
      if (this.form.username.includes('@')) return
      this.form.username = this.form.username + '@' + this.domain
    },
    clearErrors(name) {
      if (name === 'password') {
        this.form.errors.clear('password')
        name = this.name
      }
      this.form.errors.clear(name)
    }
  }
}
</script>
