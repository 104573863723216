import Vue from 'vue'

require('./bootstrap')

window._ = require('lodash')

try {
  window.$ = window.jQuery = require('jquery')

  require('bootstrap-less')
} catch (e) {
	console.info(e)
}

window.toastr = require('toastr')
require('icheck')

window.axios = require('axios')

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

Vue.component('login-form', require('./components/auth/LoginForm.vue').default)
window.vm = new Vue({
	el: '#app',
})
