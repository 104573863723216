import axios from 'axios'

const client = axios.create({
	json: true,
	withCredentials: true
})

window.apiPostPut = (payload, urlStore, urlUpdate, resourceKey) => {
	var url, method
	if (! payload.id) {
		url = route(urlStore)
		method = 'post'
	}
	else {
		url = route(urlUpdate, { [resourceKey]: payload.id })
		method = 'put'
	}
	return api[method](url, { form: payload })
}

// RESOLVER (per le promises)
window.resolver = (v) => {
	// dbg(v, '- resolver -', 'lightblue')
	return v.status
}

class ResponseObject {
	constructor(status, statusCode, statusText, data, errors) {
		this.status = status
		this.statusCode = statusCode
		this.statusText = statusText
		this.data = data

		// dbg(status, '► status ◄', 'red', 'white')
		// dbg(statusCode, '► statusCode ◄', 'red', 'white')
		// dbg(statusText, '► statusText ◄', 'red', 'white')
		// dbg(data, '► data ◄', 'red', 'white')
		// dbg(errors, '► errors ◄', 'red', 'white')

		var errorsObj = errors
		var errorsHtml = null
		if (errorsObj) {
			errorsHtml = '<br><ul>'
			Object.keys(errorsObj).forEach(k => {
				console.log()
				errorsObj[k].forEach(v => {
					errorsHtml += `<li>• ${v}</li>`
				})
			})
			// var errorsHtml = '<pre style="background-color:transparent;border:none">' + JSON.stringify(errors, null, 1) + '</pre>'
			errorsHtml += '</ul>'
		}
		this.errorsObj = errorsObj
		this.errorsHtml = errorsHtml

		if (! status && ! errorsHtml) {
			snackbar(statusText, 'red')
			notifyE(statusText)
		}
		if (errorsHtml) {
			snackbar(errorsHtml, 'red')
			notifyE(errorsHtml)
		}
	}
}
window.ResponseObject = ResponseObject

function objectToQuerystring (obj) {
	return Object.keys.reduce(function (str, key, i) {
	  var delimiter, val;
	  delimiter = (i === 0) ? '?' : '&';
	  key = encodeURIComponent(key);
	  val = encodeURIComponent(obj[key]);
	  return [str, delimiter, key, '=', val].join('');
	}, '');
}

function b64EncodeUnicode(str) {
	// first we use encodeURIComponent to get percent-encoded UTF-8,
	// then we convert the percent encodings into raw bytes which
	// can be fed into btoa.
	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode('0x' + p1);
	}));
}
window.b64EncodeUnicode = b64EncodeUnicode

export default {
	async internalCall(method, url, data) {
		try {
			if (url.includes('api.')) {
				url = route(url)
			}

			let accessToken = localStorage.getItem('token')

			// se il metodo è "get", converte l'oggetto "payload" in querystring base64
			if (method === 'get') {
				if (data) {
					if (url.includes('?')) {
						url += '&q='
					}
					else {
						url += '?q='
					}
					url += b64EncodeUnicode(JSON.stringify(data))
				}
				data = null
			}

			let promise = await client({
				method,
				url,
				data,
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
			
			return new ResponseObject(true, promise.status, promise.statusText, promise.data)
		}
		catch (error) {
			if (! error.response) { 
				var errorObject = JSON.parse(JSON.stringify(error))
				dbg(errorObject, 'ERROR w/o response', 'red')
				if (errorObject.code && errorObject.code === 'ECONNABORTED') {
					// notifyE('Network issue: timeout occurred!')
					return new ResponseObject(false, null)
				}
				console.log(error)
				return new ResponseObject(false, null, 'Network error!<br>' + error)
			}
			else {
				dbg('ERROR w/ response', null, 'firebrick')
				// console.info(error.response)
				
				let status = error.response.status
				
				// UNAUTHENTICATED
				if (status == 401) {
					$userReset()
					location.href = '/logout'
					return
					// necessario raggiungere this.clear() del componente Login.vue per svuotare l'oggetto tokens
				}
				if (status == 419) {
					dbg(error, '-= ERROR =-')
				}
				if (typeof error.response.data == 'string' && error.response.data.includes('Proxy error')) {
					// notifyE('Network issue: server unreachable!')
				}
				// else {
				//     let formattedErrors = error.response.data.errors ? '<pre>' + JSON.stringify(error.response.data.errors, undefined, 2) + '</pre>' : null
				//     dbg(formattedErrors, '-= formattedErrors =-')
				//     // notifyE(error.response.statusText + error.response.data.message + (formattedErrors || ''))
				// }
				return new ResponseObject(false, error.response.status, error.response.statusText, null, error.response.data.errors)
			}
		}
	},
	get(url, payload) {
		let execution = this.internalCall('get', url, payload)
		return execution
	},
	post(url, payload) {
		let execution = this.internalCall('post', url, payload)
		return execution
	},
	put(url, payload) {
		let execution = this.internalCall('put', url, payload)
		return execution
	},
	patch(url, payload) {
		let execution = this.internalCall('patch', url, payload)
		return execution
	},
	delete(url, payload) {
		let execution = this.internalCall('delete', url, payload)
		return execution
	}
}
