import Vue from 'vue'
window.Vue = new Vue

require('./helpers.js')

window._ = require('lodash')

try {
	window.$ = window.jQuery = require('jquery');

	require('bootstrap-less')
} catch (e) {
	console.info(e)
}

window.lcb = 'background: #222; color: aqua; font-size: 18px; font-weight: bold'
window.lcg = 'background: #222; color: lime; font-size: 18px; font-weight: bold'
window.lcr = 'background: #222; color: red; font-size: 18px; font-weight: bold'
window.lcy = 'background: #222; color: yellow; font-size: 18px; font-weight: bold'

window.floatThead = require('floatthead')
require('../node_modules/morris.js/morris.min.js')
window.Raphael = require('raphael')
require('admin-lte')
// window.vectorMap = require('jvectormap-next')
// require('./../../public/js/components/jvectormap/jquery-jvectormap-world-mill-en.js')

/* TOASTR */
window.toastr = require('toastr')
toastr.options = {
	closeButton: true,
	debug: false,
	newestOnTop: true,
	progressBar: true,
	positionClass: 'toast-bottom-right',
	preventDuplicates: false,
	onclick: null,
	showDuration: '300',
	hideDuration: '1000',
	timeOut: '5000',
	extendedTimeOut: '1000',
	showEasing: 'swing',
	hideEasing: 'linear',
	showMethod: 'slideDown',
	hideMethod: 'fadeOut'
}

/* NOTIFY - IZITOAST */
import iziToast from 'izitoast'// http://izitoast.marcelodolce.com/#Options
import 'izitoast/dist/css/iziToast.min.css'

class NotifyClass {

	get options() {
		return {
			overlayColor: 'rgba(0, 0, 0, 1)',
			position: 'topCenter',
			timeOut: 5000,
			transitionIn: 'fadeInDown',
			transitionOut: 'fadeOut'
		}
	}

	info(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Info',
			color: 'blue',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	success(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Success!',
			color: 'green',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	warning(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Warning!',
			color: 'yellow',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	error(message, overrideOptions = {}) {
		// this.hide()
		var typeOptions = {
			title: 'Error!',
			color: 'red',
			message
		}
		return iziToast.show({ ...this.options, ...typeOptions, ...overrideOptions })
	}

	hide() {
		var openToasts = document.querySelector('.iziToast')
		if (openToasts !== null) {
			return iziToast.hide(document.querySelector('.iziToast'), { transitionOut: 'fadeOutUp' })
		}
	}
}

window.Notify = new NotifyClass

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

window.notify = function (type, text, opts) {
	let notificationPlugin = 'toast'

	var check = null
	try {
		check = JSON.parse(text)
	}
	catch (error) {
		check = 'set as string'
	}
	
	if (typeof check === 'object') {
		text = JSON.stringify(JSON.parse(text), null, 2);
	}

	let title = type.capitalize() + '!'

	// "Notify" notifications
	if (notificationPlugin === 'notify') {
		Vue.notify({
			type,
			duration: -1,
			title,
			text
		})
	}

	if (notificationPlugin === 'toast') {
		if (type === 'warn') { type = 'warning' }
		if (! window.toastInstance) {
			window.toastInstance = new NotifyClass()
		}
		window.toastInstance[type](text, opts)
	}
}
window.notifyS = function (text, opts = null) {
	window.notify('success', text, opts)
}
window.notifyE = function (text, opts = null) {
	window.notify('error', text, opts)
}
window.notifyW = function (text, opts = null) {
	window.notify('warn', text, opts)
}
window.notifyI = function (text, opts = null) {
	window.notify('info', text, opts)
}

require('icheck')
window.moment = require('moment')

var axios = require('axios')
window.axios = axios
window.axios2 = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.response.use(
	(response) => {
		// console.info('RESPONSE', response)
		return response
	},
	(error) => {
		if (error.response.request.responseURL.includes('/login')) {
		console.info(error.response.data)
		return Promise.reject(error)
		}

		var status = 0
		if (error.hasOwnProperty('response')){
			status = error.response.status
		}
		console.info('ERRORE', status, error.response)

		if ([401, 419].includes(status) /*|| TokenMismatchException*/ ) {
			location.href = '/login?exp=1'
			return
		}

		return Promise.reject(error)
	}
)

let token = Laravel.csrfToken

if (token) {
	window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token; // for meta use 'token.content';
	dbg(token, '► TOKEN ◄', 'lightgreen', '#333')
} else {
	console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

// Use trans function in Vue (equivalent to trans() Laravel Translations helper). See htmlheader.balde.php partial.
Vue.prototype.trans = (key) => {
	return _.get(window.trans, key, key)
}

var env = process.env.NODE_ENV
var pusherKey
if (env === 'development') {
	dbg(env, '-= ENVIRONMENT =-', 'lightgrey', 'red')
	pusherKey = '1234567890'
}
if (env === 'production') {
	dbg(env, '-= ENVIRONMENT =-', 'lightgreen', '#333')
	pusherKey = 'cad0ffe6a1737e6f1828'
}
dbg(pusherKey, '-= PUSHER KEY =-')

window.SocketEventBus = new Vue()

import api from './api'
window.api = api

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VModal from 'vue-js-modal'
Vue.use(VModal)

import VueTippy from 'vue-tippy'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
Vue.use(VueTippy)

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('treeselect', Treeselect)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import FullCalendar from 'vue-full-calendar'
Vue.use(FullCalendar)
import 'fullcalendar/dist/fullcalendar.css'
